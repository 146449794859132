import './survey_question_step_component.css'
import { Controller } from "@hotwired/stimulus"
import { jsRequestHeaders, isTruthy, isFalsy } from '@javascript/javascripts/utils'
import axios from 'axios'
import lottie from 'lottie-web-light'

export default class extends Controller {

  static values = { messages: Object }
  static targets = ['charsCountHint', 'messagePlaceholder', 'submitButton', 'submitMatchingButton']

  initialize() {
    this.form = this.element;
    this.carousel = document.querySelector('[data-carousel]')

    this.isQuizzMode = isTruthy(this.form.dataset.isQuizz)
    this.isDisabled = isTruthy(this.form.dataset.disabled)
    this.lastStep = this.form.dataset.lastStep
    this.surveyID = this.form.dataset.surveyId
    this.isNodes = isTruthy(this.form.dataset.isNodes)
    this.surveyType = this.form.dataset.surveyType

    this.nextMessageLength =  this.form.dataset.nextMessage?.length || 0
    this.successMessageLength =  this.form.dataset.successMessage?.length || 0
    this.errorMessageLength =  this.form.dataset.errorMessage?.length || 0

    if(this.isDisabled){
      this._disableForm();
    }
    if(!this.lastStep && this.hasSubmitButtonTarget){
      [...this.form.querySelectorAll('input[type="text"], textarea')].map(input => this._handleSubmitButtonState(input))
    }
    if (this.isNodes && document.querySelector('[data-app--survey-question-step--survey-question-step-component-target="submitMatchingButton"]')) {
      this._handleNodes()
    }
  }

  submitForm(event){
    if(event.target.nodeName === 'BUTTON' || event.target.nodeName === 'FORM'){
      event.preventDefault();
    }

    let messageLength = 0;
    let attribute = this.form.getAttribute('action');
    let stats;
    if (window.self !== window.top) {
      attribute = attribute.replace('/surveys/', '/lms/surveys/') + '?jwt=' + window.localStorage.getItem('jwt')
      stats = JSON.parse(window.localStorage.getItem('quizStats-' + this.surveyID)) || { total: 0, correct: 0, status: 'unknown' }
    }

    axios.post(attribute, new FormData(this.form), jsRequestHeaders).then(response => {

      if(this.isQuizzMode){
        const expected = response.data.question.quizz.answers.sort()
        const actual = Array.from(this.form.querySelectorAll('input:checked')).map((i) => parseInt(i.value, 10)).sort()
        
        if (window.self !== window.top) {
          stats.total += 1
          stats.status = 'unknown'
        }

        if(expected.equals(actual)){
          messageLength = this.successMessageLength
          this._showMessage('correct')
          if (window.self !== window.top) {
            stats.correct += 1
          }
        } else {
          messageLength = this.errorMessageLength
          this._showMessage('incorrect')
        }

        if (this.surveyType !== 'evaluation') {
          expected.forEach((id) => {
            this.form.querySelector(`.choices label[for="q_${this.form.dataset.questionId}_c_${id}"]`).classList.add('correct')
          })
        } else {
          actual.forEach((id) => {
            if (expected.includes(id)) {
              this.form.querySelector(`.choices label[for="q_${this.form.dataset.questionId}_c_${id}"]`).classList.add('correct')
            } else {
              this.form.querySelector(`.choices label[for="q_${this.form.dataset.questionId}_c_${id}"]`).classList.add('incorrect')
            }
          })
        }
      } else {
        if(!response.data['participation_skipped']){
          messageLength = this.nextMessageLength
          this._showMessage('success')
        }
      }
      if (window.self !== window.top) {
        window.localStorage.setItem('quizStats-' + this.surveyID, JSON.stringify(stats))
      }
    })
    .catch((error) => {
      if(error.response.data.participation_errors){
        Object.values(error.response.data.participation_errors).forEach(message => {
          messageLength = message.length
          this._showMessage('warning', message)
        })
      }
    })
    .finally(() => {
      this._carouselSlideNext(messageLength, this.isQuizzMode)
    });
  }

  toggleSubmitButton(event){
    const input = event.target;
    this._handleSubmitButtonState(input)
  }

  countChars(event){
    const textarea = event.target;
    const charsLimit = parseInt(textarea.getAttribute('data-ccl'), 10);
    const charsCount = textarea.value.length

    if(charsCount > 0){
      if(charsCount >= charsLimit) { textarea.value = textarea.value.substring(0, charsLimit) }
      this.charsCountHintTarget.innerHTML = ` (${textarea.value.length}/${charsLimit})`
    } else {
      this.charsCountHintTarget.innerHTML = null
    }
  }

  _disableForm(){
    this.form.dataset.disabled = true
    this.form.action = "#"
    Array.from(this.form.querySelectorAll('input,select')).map(input => input.setAttribute('disabled', 'disabled'))
  }

  _carouselSlideNext(messageLength, isQuizzMode){
    let event = new CustomEvent('slide-next')
    event.messageLength = messageLength
    event.isQuizzMode = isQuizzMode
    this.carousel.dispatchEvent(event)
  }

  _handleSubmitButtonState(input){
    0 === input.value.length ? this._disableSubmitButton() : this._enableSubmitButton()
  }

  _disableSubmitButton(){
    this.submitButtonTarget.classList.add('btn--disabled')
    this.submitButtonTarget.disabled = 'disabled'
  }

  _enableSubmitButton(){
    this.submitButtonTarget.classList.remove('btn--disabled')
    this.submitButtonTarget.removeAttribute('disabled')
  }

  _showMessage(type, body){
    const props = this.messagesValue[type]
    this.messagePlaceholderTarget.innerText = ''

    const animation = document.createElement('span')
    animation.setAttribute('class', props.animation.styles)
    this.messagePlaceholderTarget.appendChild(animation)
    this._buildLottie(animation, props.animation.name, props.animation.speed)

    const text = document.createElement('span')
    if(body || props.text.body)
      text.innerText = body || props.text.body
    if(props.text.styles)
      text.setAttribute('class', props.text.styles)
    this.messagePlaceholderTarget.appendChild(text)
  }

  _buildLottie(container, name, speed){
    import(`@vendor/javascripts/lotties/${name}.json`).then(json => {
      const args = {
        container: container,
        animationData: json.default,
        renderer: 'svg',
        loop: false,
        autoplay: true
      }
      const instance = lottie.loadAnimation(args);
      if(speed)
        instance.setSpeed(speed)
    })
  }

  _getMessage(type){
    return this[type + 'MessageTarget']
  }

  _resetMessageAnimation(type){
    this[type + 'MessageTarget'].classList.add('hidden')
    if(this[type + 'MessageTarget'].querySelector('lottie-player')){
      this[type + 'MessageTarget'].querySelector('lottie-player').getLottie().goToAndPlay(0)
    }
  }

  _handleNodes(){
    const nodesLabel = this.form.querySelectorAll('input[name="nodes_label[]"]');
    const nodesAnswer = this.form.querySelectorAll('input[name="nodes_answer[]"]');

    localStorage.setItem('matchings', JSON.stringify([]))

    nodesLabel.forEach(node => {
      node.addEventListener('change', (event) => {
        let selectedNodeLabel = null
        const matchings = JSON.parse(localStorage.getItem('matchings'))
        const matchingsIndex = matchings.findIndex(matching => matching.labelID === event.target.id)
        if (!event.target.checked && matchingsIndex > -1) {
          const nodeAnswer = this.form.querySelector(`input[name="nodes_answer[]"][id="${matchings[matchingsIndex].answerID}"]`)
          if (nodeAnswer) {
            nodeAnswer.checked = false
            document.querySelector(`svg[data-first-element="${event.target.id}"]`).remove()
          }
          matchings.splice(matchingsIndex, 1)
        }
        nodesLabel.forEach(label => {
          if (label.checked && !matchings.find(matching => matching.labelID === label.id)) {
            selectedNodeLabel = label
          }
        })
        if (selectedNodeLabel) {
          matchings.forEach((matching, index) => {
            if (!matching.answerID) {
              const node = this.form.querySelector(`input[name="nodes_label[]"][id="${matching.labelID}"]`)
              node.checked = false
              matchings.splice(index, 1)
            }
          })
          if (!matchings.find(matching => matching.labelID === selectedNodeLabel.id)) {
            matchings.push({ labelID: selectedNodeLabel.id, label: this.form.querySelector(`label[for="${selectedNodeLabel.id}"]`).innerText })
          }
          nodesAnswer.forEach(answer => {
            if (!matchings.find(matching => matching.answerID === answer.id)) {
              answer.removeAttribute('disabled')
              const label = this.form.querySelector(`label[for="${answer.id}"]`)
              label.classList.remove('bg-gray-400')
              label.classList.remove('border-gray-400')
              label.classList.add('bg-theme-indigo')
              label.classList.add('border-theme-indigo')
            }
          })
        } else {
          nodesAnswer.forEach(answer => {
            if (!answer.checked) {
              answer.setAttribute('disabled', 'disabled')
              const label = this.form.querySelector(`label[for="${answer.id}"]`)
              label.classList.remove('bg-theme-indigo')
              label.classList.remove('border-theme-indigo')
              label.classList.add('bg-gray-400')
              label.classList.add('border-gray-400')
            }
          })
        }
        localStorage.setItem('matchings', JSON.stringify(matchings))
      })
    })

    nodesAnswer.forEach(node => {
      node.setAttribute('disabled', 'disabled')
      const label = this.form.querySelector(`label[for="${node.id}"]`)
      label.classList.remove('bg-theme-indigo')
      label.classList.remove('border-theme-indigo')
      label.classList.add('bg-gray-400')
      label.classList.add('border-gray-400')
      node.addEventListener('change', (event) => {
        let selectedNodeAnswer = null
        const matchings = JSON.parse(localStorage.getItem('matchings'))
        const matchingsIndex = matchings.findIndex(matching => matching.answerID === event.target.id)
        if (!event.target.checked && matchingsIndex > -1) {
          const nodeLabel = this.form.querySelector(`input[name="nodes_label[]"][id="${matchings[matchingsIndex].labelID}"]`)
          nodeLabel.checked = false
          document.querySelector(`svg[data-second-element="${event.target.id}"]`).remove()
          matchings.splice(matchingsIndex, 1)
          event.target.setAttribute('disabled', 'disabled')
          const label = this.form.querySelector(`label[for="${event.target.id}"]`)
          label.classList.remove('bg-theme-indigo')
          label.classList.remove('border-theme-indigo')
          label.classList.add('bg-gray-400')
          label.classList.add('border-gray-400')
        }
        nodesAnswer.forEach(answer => {
          if (answer.checked && !matchings.find(matching => matching.answerID === answer.id)) {
            selectedNodeAnswer = answer
          }
        })
        if (selectedNodeAnswer) { 
          matchings.forEach((matching, index) => {
            if (!matching.answerID) {
              matchings[index].answerID = selectedNodeAnswer.id
              matchings[index].answer = this.form.querySelector(`label[for="${selectedNodeAnswer.id}"]`).innerText

              const labelElement = this.form.querySelector(`label[for="${matchings[index].labelID}"]`)
              const answerElement = this.form.querySelector(`label[for="${matchings[index].answerID}"]`)
              this.connectByLine(labelElement, answerElement, 'for', this.form)
            }
          })
          nodesAnswer.forEach(answer => {
            const matchingIndex = matchings.findIndex(matching => matching.answerID === answer.id)
            if (selectedNodeAnswer.id !== answer.id && matchingIndex === -1) {
              answer.setAttribute('disabled', 'disabled')
              const label = this.form.querySelector(`label[for="${answer.id}"]`)
              label.classList.remove('bg-theme-indigo')
              label.classList.remove('border-theme-indigo')
              label.classList.add('bg-gray-400')
              label.classList.add('border-gray-400')
            }
          })
        }
        localStorage.setItem('matchings', JSON.stringify(matchings))
      })
    })

    this.submitMatchingButtonTarget.addEventListener('click', (event) => {
      event.preventDefault()

      let messageLength = 0;
      let attribute = this.form.getAttribute('action');
      let stats;
      if (window.self !== window.top) {
        attribute = attribute.replace('/surveys/', '/lms/surveys/') + '?jwt=' + window.localStorage.getItem('jwt')
        stats = JSON.parse(window.localStorage.getItem('quizStats-' + this.surveyID)) || { total: 0, correct: 0, status: 'unknown' }
      }

      const matchings = JSON.parse(localStorage.getItem('matchings'))
      const form_data = new FormData();
      form_data.append('participation[question_id]', this.form.dataset.questionId);
      form_data.append('participation[step_id]', this.form.closest('li').dataset.stepId);
      matchings.forEach(matching => {
        form_data.append('data[]', JSON.stringify({ label: matching.label, answer: matching.answer }));
      })

      const headers = jsRequestHeaders.headers;
      headers['x-csrf-token'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

      axios.post(attribute, form_data, { headers }).then(response => {
        if (this.isQuizzMode){
          const expected = response.data.question.quizz.answers.sort((a, b) => a.label.localeCompare(b.label))
          const actual = matchings.map(matching => ({ label: matching.label, answer: matching.answer })).sort((a, b) => a.label.localeCompare(b.label))

          if (window.self !== window.top) {
            stats.total += 1
            stats.status = 'unknown'
          }

          if(JSON.stringify(expected) === JSON.stringify(actual)){
            messageLength = this.successMessageLength
            this._showMessage('correct')
            if (window.self !== window.top) {
              stats.correct += 1
            }
          } else {
            messageLength = this.errorMessageLength
            this._showMessage('incorrect')
          }
          
          matchings.forEach((matching, index) => {
            const expectedAnswer = expected.find(expectedMatching => expectedMatching.label === matching.label)
            const nodeLabel = this.form.querySelector(`input[name="nodes_label[]"][id="${matching.labelID}"]`)
            const nodeAnswer = this.form.querySelector(`input[name="nodes_answer[]"][id="${matching.answerID}"]`)
            const labelLabel = this.form.querySelector(`label[for="${nodeLabel.id}"]`)
            const labelAnswer = this.form.querySelector(`label[for="${nodeAnswer.id}"]`)
            if (expectedAnswer.answer === labelAnswer.innerText) {
              labelLabel.style.backgroundColor = '#1bc5a9'
              labelLabel.style.borderColor = '#1bc5a9'
              labelAnswer.style.backgroundColor = '#1bc5a9'
              labelAnswer.style.borderColor = '#1bc5a9'
            } else {
              labelLabel.style.backgroundColor = '#ef4444'
              labelLabel.style.borderColor = '#ef4444'
              labelAnswer.style.backgroundColor = '#ef4444'
              labelAnswer.style.borderColor = '#ef4444'
            }
          })
        } else {
          if(!response.data['participation_skipped']){
            messageLength = this.nextMessageLength
            this._showMessage('success')
          }
        }
        if (window.self !== window.top) {
          window.localStorage.setItem('quizStats-' + this.surveyID, JSON.stringify(stats))
        }
      })
      .catch((error) => {
        if(error.response.data.participation_errors){
          Object.values(error.response.data.participation_errors).forEach(message => {
            messageLength = message.length
            this._showMessage('warning', message)
          })
        }
      })
      .finally(() => {
        localStorage.setItem('matchings', JSON.stringify([]))
        document.querySelectorAll("svg[data-first-element]").forEach(svg => svg.remove());
        this._carouselSlideNext(messageLength, this.isQuizzMode)
      });
    })
  }

  connectByLine(firstElement, secondElement, idAttribute, container = document.body){  
    if (container.style.position === '') {
      container.style.position = 'relative';
    }

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.dataset.firstElement = firstElement.getAttribute(idAttribute)
    svg.dataset.secondElement = secondElement.getAttribute(idAttribute)
    svg.style.position = "absolute";
    svg.style.top = "0";
    svg.style.left = "0";
    svg.style.width = "100%";
    svg.style.height = "100%";
    svg.style.pointerEvents = "none";
    
    const line = document.createElementNS("http://www.w3.org/2000/svg", "line");

    const updateLinePosition = () => {
      const containerRect = container.getBoundingClientRect();
      const firstRect = firstElement.getBoundingClientRect();
      const secondRect = secondElement.getBoundingClientRect();

      line.setAttribute("x1", firstRect.right - containerRect.left);
      line.setAttribute("y1", firstRect.top + firstRect.height/2 - containerRect.top);
      line.setAttribute("x2", secondRect.left - containerRect.left);
      line.setAttribute("y2", secondRect.top + secondRect.height/2 - containerRect.top);
    }

    line.setAttribute("stroke", "#4F46E5");
    line.setAttribute("stroke-width", "2");
    
    svg.appendChild(line);
    container.appendChild(svg);

    updateLinePosition();

    window.addEventListener('resize', updateLinePosition);
    window.addEventListener('scroll', updateLinePosition);
  }
}
