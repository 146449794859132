import { Controller } from "@hotwired/stimulus"
import html2pdf from 'html2pdf.js'

export default class extends Controller {
  static values = {
    account: String,
    participant: Object,
    completionDate: String,
    language: String
  }

  async getBase64FromUrl(url) {
    const response = await fetch(url + "?t=" + new Date().getTime());
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async downloadAttestationFinale(event) {
    event.preventDefault()
    await this.generateAttestation('final')
  }

  async downloadAttestationOpenBadge(event) {
    event.preventDefault()
    await this.generateAttestation('evaluation')
  }

  async generateAttestation(type) {
    const participant = JSON.parse(this.element.dataset.attestationParticipantParam)
    const lang = participant.locale || 'fr'
    
    const formatAddress = () => {
      const parts = []
      if (participant.address) parts.push(participant.address)
      if (participant.address_postal_code) parts.push(participant.address_postal_code)
      if (participant.address_city) parts.push(participant.address_city)
      if (participant.address_country) parts.push(participant.address_country)
      return parts.join(', ')
    }

    const formatDate = (dateString) => {
      if (dateString && dateString.includes('T')) {
        dateString = dateString.split('T')[0];
      }
      return new Date(dateString).toLocaleDateString(lang, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }

    const accountLogoBase64 = await this.getBase64FromUrl(this.element.dataset.attestationAccountLogoParam)
    const remixtLogoBase64 = await this.getBase64FromUrl(this.element.dataset.attestationRemixtLogoParam)

    const content = document.createElement('div')
    content.innerHTML = `
      <div class="attestation" style="padding: 40px; font-family: Arial; max-width: 800px; margin: 0 auto; border: 2px solid #e5e7eb; border-radius: 8px; background: linear-gradient(to bottom right, #ffffff, #f9fafb);">
        <div style="text-align: center; margin-bottom: 40px; border-bottom: 1px solid #e5e7eb; padding-bottom: 20px;">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
            <img src="${accountLogoBase64}" style="height: 40px; object-fit: contain;" />
            <img src="${remixtLogoBase64}" style="height: 40px; object-fit: contain;" />
          </div>
          <h1 style="color: #1f2937; font-size: 28px; margin-top: 28px; font-weight: 600;">${this.element.dataset.attestationTitleText}</h1>
        </div>
        
        <div style="margin: 40px 0; font-size: 16px; line-height: 1.8; color: #374151;">
          <p style="margin-bottom: 30px;">${this.element.dataset.attestationDeliveredByText} <strong style="color: #111827;">Remixt</strong> ${this.element.dataset.attestationCertifyText}</p>
          
          <div style="margin: 40px 0; padding: 30px; background-color: #f3f4f6; border-radius: 8px;">
            <p style="font-size: 24px; margin-bottom: 20px; color: #111827;"><strong>${participant.first_name} ${participant.last_name.toUpperCase()}</strong></p>
            <p style="margin-bottom: 10px;">${this.element.dataset.attestationIdentityText} <strong>${formatDate(participant.birthdate)}</strong></p>
            <p style="margin-bottom: 10px;">${this.element.dataset.attestationAddressText} <strong>${formatAddress()}</strong></p>
            ${participant.position ? `<p style="margin-bottom: 10px;">${this.element.dataset.attestationPositionText} <strong>${participant.position}</strong></p>` : ''}
          </div>

          <p style="margin-bottom: 15px;">${this.element.dataset.attestationTrainingText}</p>
          <p style="margin-bottom: 30px;">${this.element.dataset.attestationTrainingDateText} <strong>${formatDate(this.element.dataset.attestationCompletionDateParam)}</strong></p>
        </div>
        
        <div style="margin-top: 60px; border-top: 1px solid #e5e7eb; padding-top: 10px;">
          <p style="color: #4b5563;">${this.element.dataset.attestationMadeOnText} <strong>${formatDate(this.element.dataset.attestationCompletionDateParam)}</strong></p>
        </div>
      </div>
    `

    const options = {
      margin: 1,
      filename: type === 'evaluation' ? 'attestation_formation_open_badge.pdf' : 'attestation_formation_finale.pdf',
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    }

    html2pdf().set(options).from(content).save()
  }
}
