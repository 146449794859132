// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

require('@rails/activestorage').start()
// require('channels')

import '@javascript/javascripts/extends'
import '@javascript/stylesheets/app.css';
import '@javascript/stylesheets/shared/tooltip.css';

import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Popover, Slideover, Toggle } from "tailwindcss-stimulus-components"

const application = Application.start()
application.register('popover', Popover)
application.register('slideover', Slideover)
application.register('toggle', Toggle)

const context = require.context('../controllers', true, /.js$/)
const components = require.context('../../components', true, /(shared|app).+_controller\.js$/)
application.load(definitionsFromContext(context).concat(definitionsFromContext(components)))

document.addEventListener('turbo:before-cache', () => {
  application.controllers.forEach(controller => {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})